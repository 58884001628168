.appbar {
    /*display: flex;*/
    /*justify-content: space-between;*/
}

.colorPrimary{
    color: brown
}
.colorDefault{
    color: brown
}
.colorSecondary{
    color: brown
}

